import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import Home from '../../components/sections/home';
import Insurance from '../../components/sections/insurance';
import PlanTypes from '../../components/sections/plan-types';
import LicencedIn from '../../components/licensed-in';
import Helmet from 'react-helmet';

export default () => {
  const [currentTab, setCurrentTab] = useState('INDIVIDUAL');
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState('insurance');

  const props = {
    currentTab,
    setCurrentTab,
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout activePage={currentPage} {...props} id='insurance'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta
          name='description'
          content='Author: Apollo Technology, a place to learn more and hire health insurance, get to know more about how to be secure and get the best health-insurance for you and your family '></meta>
        <title>Health Insurance</title>
      </Helmet>

      <div className='insurance-page'>
        <Insurance {...props} />
      </div>
      <LicencedIn {...props} />
    </Layout>
  );
};
