import React from 'react';

export default props => {
  return (
    <div className='insurance'>
      <div className='anchor' id='insurance' />
      <div className='insurance-main-content'>
        <div className='text-container'>
          <div className='text-1'>
            <span>FOR YOU,</span> <span>your family,</span> <span>or even your team.</span> <span>We have the</span> <span>best coverage.</span>
          </div>
          <div className='text-2'>
            At Apollo, it’s always our mission to help you secure the best coverage at an affordable price. The only thing you have to do is answer a few
            questions and then let us do the shopping for you, it’s that simple. Our carrier list is constantly growing and allowing us to offer you more
            services. Let us help you, besides you else do you know that can be helpful, trusted and have a group of knowledgeable advisors?
          </div>
        </div>
        <div className='text-3'>
          CHOOSE THE PLAN<span>THAT BEST FITS YOU</span>
          <div className='buttons-container'>
            <div className='buttons-container-first-row'>
              <a href='/insurance/individual' className='insurance-button'>
                <div className='insurance-button-img'>
                  <svg>
                    <use xlinkHref='#individual-icon'>
                      <link rel='stylesheet' href='/sprite.css'></link>
                    </use>
                  </svg>
                </div>
                <div className='insurance-button-text'>
                  <div className='insurance-button-text-bold'>INDIVIDUAL</div>
                  <div>INSURANCE</div>
                </div>
              </a>
              <a href='/insurance/group' className='insurance-button'>
                <div className='insurance-button-img'>
                  <svg>
                    <use xlinkHref='#group-icon' />
                  </svg>
                </div>
                <div className='insurance-button-text'>
                  <div className='insurance-button-text-bold'>GROUP</div>
                  <div>INSURANCE</div>
                </div>
              </a>
              <a href='/insurance/life' className='insurance-button'>
                <div className='insurance-button-img'>
                  <svg>
                    <use xlinkHref='#critical-illness-2-icon' />
                  </svg>
                </div>
                <div className='insurance-button-text'>
                  <div className='insurance-button-text-bold'>LIFE</div>
                  <div>INSURANCE</div>
                </div>
              </a>
            </div>
            <div className='buttons-container-first-row'>
              <a href='/medicare' className='insurance-button hidden-on-mobile'>
                <div className='insurance-button-img'>
                  <svg>
                    <use xlinkHref='#group-icon' />
                  </svg>
                </div>
                <div className='insurance-button-text'>
                  <div className='insurance-button-text-bold'>MEDICARE</div>
                  <div>INSURANCE</div>
                </div>
              </a>
              <a href='/insurance/vision' className='insurance-button'>
                <div className='insurance-button-img'>
                  <svg>
                    <use xlinkHref='#vision-icon' />
                  </svg>
                </div>
                <div className='insurance-button-text'>
                  <div className='insurance-button-text-bold'>VISION</div>
                  <div>INSURANCE</div>
                </div>
              </a>
              <a href='/insurance/dental' className='insurance-button'>
                <div className='insurance-button-img'>
                  <svg>
                    <use xlinkHref='#dental-icon' />
                  </svg>
                </div>
                <div className='insurance-button-text'>
                  <div className='insurance-button-text-bold'>DENTAL</div>
                  <div>INSURANCE</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='insurance-model'>
        <img alt='dad mom daughter and son together' src='/insurance-model.png' />
      </div>
    </div>
  );
};
